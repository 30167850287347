<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          User List
          <v-spacer></v-spacer>
          <v-avatar color="primary">
            <v-icon dark>mdi-account-multiple</v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="table.headers" :items="allUsers">
            <template v-slot:item.role="{ item }">
              {{ item.role | capitalize }}
            </template>

            <template v-slot:item.created_at="{ item }">
              {{ item.created_at | moment }}
            </template>

            <template v-slot:item.updated_at="{ item }">
              {{ item.updated_at | moment }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-row no-gutters class="px-2">
                <v-btn icon @click="editData(item)">
                  <v-icon>mdi-file-edit</v-icon>
                </v-btn>
                <v-btn icon @click="deleteConfirmation(item)">
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
          <br />
        </v-card-text>
      </v-card>
      <!-- <iframe src="https://www.tiktok.com/embed/v2/6718335390845095173" frameborder="0" height="100%" width="100%"></iframe> -->
    </v-col>

    <v-dialog v-model="edit.dialog" width="800" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Edit User</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="edit.dialog = false" color="error">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="edit" v-if="edit.data">
            <v-row>
              <v-col>
                <v-text-field
                  label="Name"
                  v-model="edit.data.name"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Email"
                  v-model="edit.data.email"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="Role"
                  :items="roles"
                  item-text="text"
                  item-value="value"
                  v-model="edit.data.role"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row v-if="password.change">
              <v-col>
                <v-text-field
                  :type="password.type"
                  label="Password"
                  v-model="edit.data.password"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field :type="password.type" label="Confirm Password">
                  <template v-slot:append-outer>
                    <v-btn icon @click="showPassword">
                      <v-icon>{{ password.icon }}</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-btn text color="accent" @click="changePassword">
                <span>{{ password.text }}</span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn depressed color="primary" @click="saveData">
                <span>save</span>
                <v-icon right>mdi-content-save</v-icon>
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="del.dialog" width="500" persistent>
      <v-alert dark class="mb-0" prominent icon="mdi-alert" color="warning">
        <v-row no-gutters class="align-center">
          Yakin Ingin Menghapus Data Ini?
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleDelete"
            :loading="del.loading"
            :disabled="del.disable"
          >
            <span>ya</span>
          </v-btn>
          <v-btn class="ml-2" @click="del.dialog = false">
            <span>batal</span>
          </v-btn>
        </v-row>
      </v-alert>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" multi-line>
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>
      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn text @click="snackbar.show = false">
          <span>close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      roles: [
        { text: "Admin", value: "admin" },
        { text: "Agent", value: "agent" },
        { text: "Member", value: "member" },
      ],
      table: {
        headers: [
          { text: "Name", value: "name" },
          { text: "Email", value: "email" },
          { text: "Role", value: "role" },
          { text: "Registered At", value: "created_at" },
          { text: "Last Update", value: "updated_at" },
          { text: "Actions", value: "actions" },
        ],
      },
      edit: {
        dialog: false,
        loading: false,
        disabled: false,
        data: null,
      },
      del: {
        dialog: false,
        loading: false,
        disable: false,
        id: null,
      },
      password: {
        change: false,
        show: false,
        type: "password",
        icon: "mdi-eye",
        text: "change password",
      },
      snackbar: {
        show: false,
        color: null,
        icon: null,
        text: null,
      },
      tiktok: null,
    };
  },
  methods: {
    ...mapActions(["updateUser", "deleteUser"]),
    editData(value) {
      this.edit.dialog = true;
      this.edit.data = value;
    },
    changePassword() {
      this.password.change = !this.password.change;
      this.password.text = this.password.change ? "cancel" : "change password";
      if (this.password.change) {
        this.edit.data["password"] = null;
      } else {
        delete this.edit.data.password;
      }
      // console.log(this.edit.data)
    },
    showPassword() {
      this.password.show = !this.password.show;
      this.password.icon = this.password.show ? "mdi-eye-off" : "mdi-eye";
      this.password.type = this.password.show ? "text" : "password";
    },
    deleteConfirmation(value) {
      this.del.dialog = true;
      this.del.id = value.id;
    },
    saveData() {
      let data = this.edit.data;
      console.log(data);
      this.edit.loading = true;
      this.edit.disabled = true;

      if (this.updateUser(data)) {
        this.edit.disabled = false;
        this.edit.loading = false;
        this.showNotification("success");
      } else {
        this.edit.loading = false;
        this.edit.disabled = false;
        this.showNotification("error");
      }
    },
    handleDelete() {
      let id = this.del.id;
      this.del.loading = true;
      this.del.disable = true;

      if (this.deleteUser(id)) {
        this.del.loading = false;
        this.del.disable = false;
        this.del.dialog = false;
        this.showNotification("success");
      } else {
        this.del.disable = false;
        this.del.loading = false;
        this.showNotification("error");
      }
    },
    showNotification(status) {
      this.snackbar.show = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check";
          this.snackbar.text = "Data berhasil diperbarui";
          break;
        case "error":
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close";
          this.snackbar.text = "Data gagal diperbarui";
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["allUsers"]),
  },
  filters: {
    moment: (value) => {
      return moment(value).format("Do MMM YYYY");
    },
    capitalize: (value) => {
      return value
        .toLowerCase()
        .replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
    },
  },
};
</script>